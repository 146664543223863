import React from "react";

export const questions = [
    {
        heading: "How do I recharge a prepaid meter on the Kuda app?",
        list: (
            <span className="flex flex-column">
                <span>To buy meter units on your Kuda app, follow these steps:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
                        <li className="mb-3">Sign in to your Kuda app, then tap Pay.</li>
                        <li className="mb-3">Tap Pay A Bill.</li>
                        <li className="mb-3">Tap Electricity.</li>
                        <li className="mb-3">Choose your electricity service provider (distribution company).</li>
                        <li className="mb-3">Choose the prepaid package.</li>
                        <li className="mb-3">Type in your prepaid meter number and the amount of meter units you want to buy.</li>
                        <li className="mb-3">Complete your payment with your transaction PIN, fingerprint or Face ID.</li>
                    </ol>
                    <span>We’ll send your meter token to the email address linked to your Kuda account.</span>
                </span>
            </span>),
        index: 1
    },
    {
        heading: "How do I make a postpaid meter payment on the Kuda app?",
        list: (
            <span className="flex flex-column">
                <span>To buy meter units on your Kuda app, follow these steps:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
                        <li className="mb-3">Sign in to your Kuda app, then tap Pay.</li>
                        <li className="mb-3">Tap Pay A Bill.</li>
                        <li className="mb-3">Tap Electricity.</li>
                        <li className="mb-3">Choose your electricity service provider (distribution company).</li>
                        <li className="mb-3">Choose the prepaid package.</li>
                        <li className="mb-3">Type in your prepaid meter number and the amount of meter units you want to buy.</li>
                        <li className="mb-3">Complete your payment with your transaction PIN, fingerprint or Face ID.</li>
                    </ol>
                    <span>We’ll send your meter token to the email address linked to your Kuda account.</span>
                </span>
            </span>),
        index: 2
    },
    {
        heading: (<div className="pr-5">
            Which electricity distribution companies can I buy meter tokens from on the Kuda app?
        </div>),
        list: (
            <div className="flex flex-column">
                <div className="mb-3">To buy meter units on your Kuda app, follow these steps:</div>
                <div className="mb-3">AEDC - Abuja Electricity Distribution Company</div>
                <div className="mb-3">BEDC - Benin Electricity Distribution Company</div>
                <div className="mb-3">EEDC - Enugu Electricity Distribution Company</div>
                <div className="mb-3">EKEDC - Eko Electricity Distribution Company</div>
                <div className="mb-3">IBEDC - Ibadan Electricity Distribution Company</div>
                <div className="mb-3">IKEDC - Ikeja Electricity Distribution Company</div>
                <div className="mb-3">JEDC - Jos Electricity Distribution Company</div>
                <div className="mb-3">KAEDCO - Kaduna Electricity Distribution Company</div>
                <div className="mb-3">KEDCO - Kano Electricity Distribution Company</div>
                <div className="mb-3">PHED - Port Harcourt Electricity Distribution Company</div>

            </div>),
        index: 3
    },
    {
        heading: "How do I load my meter token?",
        list: (
            <div className="flex flex-column">
                <span>To load your meter token, follow these steps:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
                        <li className="mb-3">Check your email for your meter token.</li>
                        <li className="mb-3">On your meter, type in the code on your token, then press the Enter button.</li>
                    </ol>
                    <span>Your meter should be recharged immediately.</span>
                </span>
            </div>),
        index: 4
    },
    {
        heading: "Why am I getting a ‘Reject’ error when I try to load a meter token?",
        list: (
            <div className="flex flex-column">
                <div>You might be getting a ‘Reject’ error on your meter for any of these reasons:</div>
                <div className="text-bold py-2">1. Your meter number is wrong.</div>
                <p>You might have typed in the wrong meter number when you were buying meter units on your Kuda app. To confirm that you typed in the right meter number, check the transaction receipt on your Kuda dashboard or check your transaction notification email.</p>
                <div className="text-bold py-2">2. Your meter hasn’t been activated.</div>
                <p>Every new meter has to be activated before it can accept tokens. To activate your meter, ask your electricity distribution company for an activation code and type in the code on your meter. After this, your meter will accept tokens.</p>
                <div className="text-bold py-2">3. Your meter smart card isn’t working.</div>
                <p>If you use a smart card meter, the chip on the card might be dirty. To clean the chip, remove the smart card from your meter, wipe it thoroughly with a dry piece of cloth, then put the smart card back into your meter and try to load the token.</p>
                <div className="text-bold py-2">4. Your meter isn’t properly connected.</div>
                <p>Your meter could be on but not properly connected. To fix this, reach out to your electricity distribution company.</p>
                <div className="text-bold py-2">5. Your meter is in tamper mode.</div>
                <p>If your meter is in tamper mode, reach out to your electricity distribution company to get a tamper token, load the token, then try to recharge your meter.</p>
                <div className="text-bold py-2">6. Your electricity tariff plan has changed.</div>
                <p>If the tariff plan in your location has changed, you won’t be able to recharge your meter until it has been reconfigured for the new tariff plan.</p>
                <div className="pt-2">To reconfigure your meter, reach out to your electricity distribution company to get a Key Change Token (KTC) which you’ll load on your meter. After this, you’ll be able to recharge your meter.</div>
               
            </div>),
        index: 5
    },
    {
        heading: "I’m getting a ‘Link’ error on my meter. What does this mean?",
        list: (
            <div className="flex flex-column">
                <div>A ‘Link’ error means your meter isn’t connecting to your interface unit (the unit with the keypad). This might be happening for any of these reasons:</div>
                <div className="text-bold py-2">1. Your meter doesn’t have power supply.</div>
                <p>To fix this, check if there’s electricity in your location. If there’s electricity, check that the phase you’re in has power supply. Make sure your generator or inverter is off, if you own one. Once your meter has power supply, you can load your token.</p>
                <div className="text-bold py-2">2. The batteries in your interface unit (keypad) are too weak.</div>
                <p>To fix this, replace the weak batteries with new ones, then turn on the unit if it doesn’t come back on automatically.</p>
                <div className="text-bold pt-2">3. You need to reset your interface unit (keypad).</div>
                <div className="pb-2">To reset the interface unit (keypad), follow these steps:</div>
                <div className="pt-2">1. If your meter still has units, plug the interface unit (keypad) into a 13-amp socket - usually the socket for an AC, freezer or electric cooker - then turn on the unit.</div>
                <div className="pt-2">2. Replace the first four digits of your meter number with ‘5258’, then type the new number into your interface unit (keypad) and press the ‘Enter’ button. </div>
                <div className="pt-2">For example, if your meter number is 123456789, you’ll replace ‘1234’ with ‘5258’, so the new number you’ll type in will be 525856789. </div>
                <div className="pt-2">3. Load your meter token like you normally would. </div>
            </div>),
        index: 6
    },
]