import React, { useEffect, Fragment } from "react";
import EntryIllustration from "../../../../assets/img/prepaid-electricity.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import LightBulbIcon from "../../../../assets/img/kuda-lightbulb.inline.svg"
import MailIcon from "../../../../assets/img/kuda-mail.inline.svg"
import PhoneIcon from "../../../../assets/img/airtime-icon-blue.inline.svg"
import PaymentIllustration from "../../../../assets/img/kuda-electricity-payment.inline.svg"
import ProviderIllustration from "../../../../assets/img/kuda-choose-provider.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import CTA from "../general/cta";
import { questions } from "./static-data/electricity-questions";
import MoreForYouComponent from "../general/more-for-you";
import KudaInternetIcon from '../../../../assets/img/more-for-you/kuda-internet-icon.inline.svg'
import KudaAirtimeIcon from '../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import KudaTVIcon from '../../../../assets/img/more-for-you/kuda-tv-icon.inline.svg'

const entryContent = {
    title: "Recharge your prepaid electricity meter any time on the Kuda app.",
    subtitle: "Meter top-ups are super-convenient on Kuda. Buy units in seconds and get your token by email.",
    illustration: <EntryIllustration />
}
const kudaTopFeatures = [
    {
        icon: <LightBulbIcon />,
        text: "Buy meter units from any electricity distribution company.",
    },
    {
        icon: <MailIcon />,
        text: "Get your meter token by email.",
    },
    {
        icon: <PhoneIcon />,
        text: "Top up your meter units any time.",
    },
]
const meteredUnits = {
    title: "Meter units finished at midnight? Buy more on the Kuda app.",
    subtitle: "Kuda takes the stress out of recharging your prepaid meter whenever you need to.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <PaymentIllustration
    />,
}
const chooseProvider = {
    title: "Find all major electricity distribution companies on the Kuda app.",
    subtitle: "Wherever you are, you can buy prepaid meter units from any distribution company in Nigeria.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <ProviderIllustration />,
}

const moreForYou = [
    {
        icon: <KudaInternetIcon />,
        title: "Internet",
        subText: `Buy internet data from major Nigerian internet service providers easily on your Kuda app.`,
        linkTo: `/en-ng/bill-payments/internet/`
    },
    {
        icon: <KudaAirtimeIcon />,
        title: "Airtime",
        subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
        linkTo: `/en-ng/airtime/`
    },
    {
        icon: <KudaTVIcon />,
        title: "TV",
        subText: `Pay DStv, Showmax, GOtv or StarTimes subscriptions easily and never miss out on any thing.`,
        linkTo: `/en-ng/bill-payments/tv/`
    },

]
const Electricity = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={meteredUnits.title}
                subtitle={meteredUnits.subtitle}
                illustration={meteredUnits.illustration}
                name={meteredUnits.name}
                url={meteredUnits.url}
            />
            <ImageTextLeft
                title={chooseProvider.title}
                subtitle={chooseProvider.subtitle}
                illustration={chooseProvider.illustration}
                name={chooseProvider.name}
                url={chooseProvider.url}
            />
            <FAQList title={"Electricity Payments FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTA />
        </Fragment>
    )
}

export default Electricity;
